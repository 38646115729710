/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FanEventDetailsView { 
    StartDateTime?: Date;
    readonly StartTime?: string | null;
    Name?: string | null;
    EndDateTime?: Date;
    FantastixManageableFrom?: Date | null;
    FantastixManageableTill?: Date | null;
    FantastixOnSaleFrom?: Date | null;
    FantastixOnSaleTill?: Date | null;
}

