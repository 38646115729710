/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FanCrossSellProductVariantView { 
    CrossSellProductVariantId?: number;
    EventCrossSellProductVariantId?: number;
    CrossSellProductName?: string | null;
    CrossSellProductDescription?: string | null;
    CrossSellProductDescriptionLong?: string | null;
    AmountInVat?: number;
    FantastixManageableFrom?: Date | null;
    FantastixManageableTill?: Date | null;
    IsFantastixManageable?: boolean;
    AvailableCount?: number | null;
    CurrentStockCount?: number | null;
    SoldCount?: number | null;
    HasBeenSold?: boolean;
    FantastixOnSaleFrom?: Date | null;
    FantastixOnSaleTill?: Date | null;
    OnSaleFrom?: Date | null;
    OnSaleTill?: Date | null;
}

