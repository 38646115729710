/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FanResellerDetailsView { 
    SalesChannelName?: string | null;
    ResellerId?: number;
    ResellerWebsite?: string | null;
    ResellerEmail?: string | null;
    ResellerIBAN?: string | null;
    AllowNewMembers?: boolean | null;
    LocationName?: string | null;
    LocationStreet?: string | null;
    LocationStreetNr?: number | null;
    LocationStreetNrAddition?: string | null;
    LocationPostalCode?: string | null;
    LocationCity?: string | null;
    LocationCountryId?: number;
    LocationCountry?: string | null;
    SecondaryLocationName?: string | null;
    SecondaryLocationStreet?: string | null;
    SecondaryLocationStreetNr?: number | null;
    SecondaryLocationStreetNrAddition?: string | null;
    SecondaryLocationPostalCode?: string | null;
    SecondaryLocationCity?: string | null;
    SecondaryLocationCountryId?: number | null;
    SecondaryLocationCountry?: string | null;
}

