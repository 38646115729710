/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FanOrderView { 
    Id?: number;
    TotalExVat?: number;
    TotalInclVat?: number;
    Vat?: number;
    OrderDate?: Date;
    Reference?: string | null;
    Payed?: boolean;
    Summary?: string | null;
    InitiativeName?: string | null;
    CustomerName?: string | null;
}

