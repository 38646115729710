/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type TicketSwapStatusEnum = 'None' | 'Offered' | 'Accepted' | 'Sold' | 'Withdrawn' | 'Revoked' | 'PendingInvitation' | 'InvitationDeclined' | 'Assigned' | 'AssignmentRequired';

export const TicketSwapStatusEnum = {
    None: 'None' as TicketSwapStatusEnum,
    Offered: 'Offered' as TicketSwapStatusEnum,
    Accepted: 'Accepted' as TicketSwapStatusEnum,
    Sold: 'Sold' as TicketSwapStatusEnum,
    Withdrawn: 'Withdrawn' as TicketSwapStatusEnum,
    Revoked: 'Revoked' as TicketSwapStatusEnum,
    PendingInvitation: 'PendingInvitation' as TicketSwapStatusEnum,
    InvitationDeclined: 'InvitationDeclined' as TicketSwapStatusEnum,
    Assigned: 'Assigned' as TicketSwapStatusEnum,
    AssignmentRequired: 'AssignmentRequired' as TicketSwapStatusEnum
};

