import {Component, Input} from '@angular/core';
import {FormGroup} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {KeyValuePair} from '../../../models/common/key-value-pair';
import {ValidationMessage} from '../../../models/forms/validation-message';
import {LoggingService} from '../../../services/logging.service';

@Component({
 selector: 't4-display-validation',
 templateUrl: 'display-validation.component.html'
})

export class DisplayValidationComponent {
  public debugMode: boolean;

  constructor(private logging: LoggingService,
              private translate: TranslateService)
  {
    this.debugMode = !!sessionStorage.getItem('DEBUG');
  }

  public formToValidate: FormGroup;
  public validationMessages: Array<ValidationMessage>;
  public formControlName: string;
  private baseValues: Array<ValidationMessage> = [
    {type: 'required', message: 'FORM.VALIDATION.REQUIRED'},
    {type: 'minlength', message: 'FORM.VALIDATION.MINLENGTH'},
    {type: 'maxlength', message: 'FORM.VALIDATION.MAXLENGTH'},
    {type: 'phonenumber', message: 'FORM.VALIDATION.PHONENUMBER'},
    {type: 'email', message: 'FORM.VALIDATION.EMAIL'},
    {type: 'emailTaken', message: 'FORM.VALIDATION.EMAILTAKEN'},
    {type: 'validateIban', message: 'FORM.VALIDATION.IBAN'},
    {type: 'url', message: 'FORM.VALIDATION.URL'},
    {type: 'codetaken', message: 'FORM.VALIDATION.CODETAKEN'},
    {type: 'nametaken', message: 'FORM.VALIDATION.NAMETAKEN'},
    {type: 'invalidFormat', message: 'FORM.VALIDATION.INVALIDFORMAT'},
    {type: 'max', message: 'FORM.VALIDATION.MAX'},
    {type: 'min', message: 'FORM.VALIDATION.MIN'},
    {type: 'startDateAfterEndDate', message: 'FORM.VALIDATION.STARTDATEAFTERENDDATE'}, 
    {type: 'endDateBeforeStartDate', message: 'FORM.VALIDATION.ENDDATEBEFORESTARTDATE'},
    {type: 'invalidTimeFormat', message: 'FORM.VALIDATION.INVALIDTIMEFORMAT'}, 
    {type: 'keytaken', message: 'FORM.VALIDATION.KEYTAKEN'}, 
    {type: 'number', message: 'FORM.VALIDATION.NUMBER'}, 
    {type: 'prefixtaken', message: 'FORM.VALIDATION.PREFIXTAKEN'},
    {type: 'minDate', message: 'FORM.VALIDATION.MINDATE'},
    {type: 'maxDate', message: 'FORM.VALIDATION.MAXDATE'}
  ];

  @Input()
  set form(f: FormGroup) {
    this.formToValidate = f;
  }

  private passedMessages: KeyValuePair<Array<ValidationMessage>> = null;
  @Input()
  set messages(m: KeyValuePair<Array<ValidationMessage>>) {
    this.passedMessages = m;
    if(this.formControlName) {
      const baseMessages = Object.assign({}, ...this.baseValues.map(bv => ({[bv.type]: bv.message})));
      let customMessages = {};
      if(m && m[this.formControlName])
        customMessages = Object.assign({}, ...m[this.formControlName].map(bv => ({[bv.type]: bv.message})));
      const messages: KeyValuePair<string> = {...baseMessages, ...customMessages};

      try{
        this.validationMessages = Object.keys(messages).map(type => ({
          type: type,
          message: this.translate.instant(messages[type])
        }));
      }
      catch{}
      
    
    }
  }

  @Input()
  set controlName(c: string) {
    this.formControlName = c;
    this.messages = this.passedMessages;
  }
}
