/**
 * Tymes4 Ticketing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type ShopDeliveryEnum = 'ETicket' | 'Mobile' | 'Both';

export const ShopDeliveryEnum = {
    ETicket: 'ETicket' as ShopDeliveryEnum,
    Mobile: 'Mobile' as ShopDeliveryEnum,
    Both: 'Both' as ShopDeliveryEnum
};

